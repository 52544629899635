import React, { useState, useEffect } from "react";
import Base from "../Config/Base";
import PlaneImg from '../../Assests/Images/plane.png';
import AppleImg from '../../Assests/Images/apple.png';
import LockImg from '../../Assests/Images/lock.png';
import AndroidImg from '../../Assests/Images/android.png';
import MessageImg from '../../Assests/Images/message.png';
import { BASE_API_URL, JWT_TOKEN, JWT_TOKEN_POST } from "../Config/Config";
import axios from 'axios';


export default function MyAccount () {

    const [userData, setUserData] = useState([]);
    const [activeContent, setActiveContent] = useState('myDetailsContent');
    const [cartItems, setCartItems] = useState([]);
    const [favItems, setFavItems] = useState([]);

    useEffect(() => {
        const storedUserData = sessionStorage.getItem('UserData');
        if (storedUserData) {
            try {
                setUserData(JSON.parse(storedUserData));
            } catch (error) {
                console.error("Error parsing storedUserData:", error);
            }
        }
    }, []);
    
    const handleContentChange = (content) => {
        setActiveContent(content);
    };

    useEffect(() => {
        const fetchCartItems = async () => {
            try {
                const formData = new FormData();
                formData.append("user_id", userData.id);

                const response = await axios.post(`${BASE_API_URL}get_user_cart`, formData, {
                    headers: {
                        Authorization: `${userData?.apikey}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                setCartItems(response.data.data);
                console.log(response.data, 'user cart items...')
            } catch (err) {
                console.log('failed fetching cart items..!');
            }
        };

        const fetchFavItems = async () => {
            try {
                const formData = new FormData();
                formData.append("user_id", userData.id);

                const response = await axios.post(`${BASE_API_URL}get_favorites`, formData, {
                    headers: {
                        Authorization: `${userData?.apikey}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                setFavItems(response.data.data);
                console.log(response.data.data, 'favorites.... in myaccoutn')
            } catch (err) {
                console.log('failed fetching fav items..!');
            }
        };

        fetchCartItems();
        fetchFavItems();
    }, []);

    return (
        <Base>
            <div className="bread container my-4">
                <p className="font-rock">My Account <i className="fa-solid fa-chevron-right fa-sm"></i> <span id="breadcrumbText">My Details</span></p>
            </div>
            <div className="container my-md-5">
                <div className="row">
                    <div className="col-md-3 border-right-5 my-3">
                        <p className="fw-bold">Account details</p>
                        <ul className="list-group" id="menu">
                    <li
                        className={`list-group-item ${activeContent === 'myDetailsContent' ? 'active' : ''}`}
                        data-content="myDetailsContent"
                        data-breadcrumb="My Details"
                        onClick={() => handleContentChange('myDetailsContent')}
                    >
                        My details
                    </li>
                    <li
                        className={`list-group-item ${activeContent === 'myWishlistContent' ? 'active' : ''}`}
                        data-content="myWishlistContent"
                        data-breadcrumb="My Wishlist"
                        onClick={() => handleContentChange('myWishlistContent')}
                    >
                        My wishlist
                    </li>
                    <li
                        className={`list-group-item ${activeContent === 'myCartContent' ? 'active' : ''}`}
                        data-content="myCartContent"
                        data-breadcrumb="My Wishlist"
                        onClick={() => handleContentChange('myCartContent')}
                    >
                        My cart
                    </li>
                    <li
                        className={`list-group-item ${activeContent === 'myWalletContent' ? 'active' : ''}`}
                        data-content="myWalletContent"
                        data-breadcrumb="My Wallet"
                        onClick={() => handleContentChange('myWalletContent')}
                    >
                        My wallet
                    </li>
                    <li
                        className={`list-group-item ${activeContent === 'myOrdersContent' ? 'active' : ''}`}
                        data-content="myOrdersContent"
                        data-breadcrumb="My Orders"
                        onClick={() => handleContentChange('myOrdersContent')}
                    >
                        My orders
                    </li>
                    <li
                        className={`list-group-item ${activeContent === 'savedCardsContent' ? 'active' : ''}`}
                        data-content="savedCardsContent"
                        data-breadcrumb="My Saved Cards"
                        onClick={() => handleContentChange('savedCardsContent')}
                    >
                        My saved cards
                    </li>
                    <li
                        className={`list-group-item ${activeContent === 'signOutContent' ? 'active' : ''}`}
                        data-content="signOutContent"
                        data-breadcrumb="Sign Out"
                        onClick={() => handleContentChange('signOutContent')}
                    >
                        Sign out
                    </li>
                </ul>
                    </div>

                    <div className="col-md-9 my-3">
                        <div id="contentArea" className="ps-2">
                            {activeContent === 'myDetailsContent' && 
                                <div id="myDetailsContent" className="content-section">
                                    <h2 className="mb-4">MY DETAILS</h2>
                                    <hr/>
                                    <div className="container py-4">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-9">
                                                <form id="detailsForm">
                                                    <h5 className="mb-md-4 mb-3 fw-bold">Account details</h5>
                                                    <div className="mb-md-4 mb-3">
                                                        <input type="text" id="username"
                                                            className="form-control rounded-0 border-secondary"
                                                            placeholder="User name" required/>
                                                    </div>
                                                    <div className="mb-md-4 mb-3">
                                                        <input type="email" id="email"
                                                            className="form-control rounded-0 border-secondary" placeholder="Email"
                                                            required/>
                                                    </div>
                                                    <div className="mb-md-4 mb-3">
                                                        <input type="password" id="password"
                                                            className="form-control rounded-0 border-secondary"
                                                            placeholder="Password" required/>
                                                    </div>
                                                    <div className="mb-md-4 mb-3">
                                                        <input type="password" id="confirmPassword"
                                                            className="form-control rounded-0 border-secondary"
                                                            placeholder="Confirm password" required/>
                                                    </div>

                                                    <h5 className="mb-md-4 mb-3 fw-bold">Shipping details</h5>
                                                    <div className="mb-md-4 mb-3">
                                                        <input type="text" id="firstName"
                                                            className="form-control rounded-0 border-secondary"
                                                            placeholder="First name" required/>
                                                    </div>
                                                    <div className="mb-md-4 mb-3">
                                                        <input type="text" id="lastName"
                                                            className="form-control rounded-0 border-secondary"
                                                            placeholder="Last name" required/>
                                                    </div>
                                                    <div className="mb-md-4 mb-3">
                                                        <input type="tel" id="phone"
                                                            className="form-control rounded-0 border-secondary"
                                                            placeholder="Phone number" required/>
                                                    </div>
                                                    <div className="mb-md-4 mb-3">
                                                        <select id="country" className="form-select" required>
                                                            <option value="">Select a country</option>
                                                            <option value="US">United States</option>
                                                            <option value="IN">India</option>
                                                            <option value="UK">United Kingdom</option>
                                                        </select>
                                                    </div>
                                                    <div className="mb-md-4 mb-3">
                                                        <input type="text" id="addressLine1"
                                                            className="form-control rounded-0 border-secondary"
                                                            placeholder="Address line1" required/>
                                                    </div>
                                                    <div className="mb-md-4 mb-3">
                                                        <input type="text" id="city"
                                                            className="form-control rounded-0 border-secondary" placeholder="City"
                                                            required/>
                                                    </div>
                                                    <div className="mb-md-4 mb-3">
                                                        <input type="text" id="state"
                                                            className="form-control rounded-0 border-secondary" placeholder="State"
                                                            required/>
                                                    </div>
                                                    <div className="mb-md-4 mb-3">
                                                        <input type="text" id="postcode"
                                                            className="form-control rounded-0 border-secondary"
                                                            placeholder="Postcode/ZIP" required/>
                                                    </div>
                                                    <div className="mb-md-4 mb-3 d-flex">
                                                        <input type="checkbox" className="p-2 me-2" required/>
                                                        <p className="mb-0">Use shipping details for billing</p>
                                                    </div>

                                                    <button type="submit"
                                                        className="bg-secondary text-white border-0 px-4 py-2 fw-bold">UPDATE
                                                        DETAILS</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeContent === 'myWishlistContent' && 
                                <div id="myWishlistContent" className="content-section">
                                    <h2 className="mb-4">MY WISHLIST</h2>
                                    <hr/>
                                    <div className="wish-head pb-3 border-bottom-2">
                                        <div className="row">
                                            <div className="col-lg-6 col-5">
                                                <p className="mb-0">Product</p>
                                            </div>
                                            <div className="col-lg-2 col-2">
                                                <p className="mb-0">Price</p>
                                            </div>
                                            <div className="col-lg-2 col-3">
                                                <p className="mb-0">Quantity</p>
                                            </div>
                                            <div className="col-lg-2 col-2 d-flex justify-content-end">
                                                <p className="mb-0">Total</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wish-content py-3 border-bottom-2">
                                        <div className="row">
                                            <div className="col-lg-6 col-5">
                                                <div className="wish-details row align-items-center my-2">
                                                    <div className="col-12 col-lg-4 my-2">
                                                        <img src="images/products/Purple Majesty.png" className="img-fluid w-100"
                                                            alt="Purple Majesty"/>
                                                    </div>
                                                    <div className="col-12 col-lg-8 ps-lg-4 my-2">
                                                        <h6 className="fw-bold">Purple Majesty</h6>
                                                        <p className="fst-italic mb-0">by Soga International</p>
                                                        <div className="wish-ratings text-secondary">
                                                            <span>★</span> <span>★</span> <span>★</span> <span>★</span>
                                                            <span>★</span>
                                                            <p className="text-muted">(No Reviews)</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                            <div className="col-lg-2 col-3  d-flex align-items-center">
                                                <div className="">
                                                    <div className="d-flex align-items-center px-md-3 border-secondary">
                                                        <button
                                                            className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4">-</button>
                                                        <input type="text"
                                                            className="form-control text-center mx-1 quantity-input border-0"
                                                            style={{ width: '40px'}} value="1"/>
                                                        <button
                                                            className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4">+</button>
                                                    </div>
                                                    <p className="fs-8 mb-0 mt-3">Remove Item <span
                                                            className="remove-wish ms-1 rounded-circle">x</span></p>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center justify-content-end">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <button
                                                className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold">Add
                                                To Cart</button>
                                        </div>
                                    </div>
                                    <div className="wish-content py-3 border-bottom-2">
                                        <div className="row">
                                            <div className="col-lg-6 col-5">
                                                <div className="wish-details row align-items-center my-2">
                                                    <div className="col-12 col-lg-4 my-2">
                                                        <img src="images/products/Purple Majesty.png" className="img-fluid w-100"
                                                            alt="Purple Majesty"/>
                                                    </div>
                                                    <div className="col-12 col-lg-8 ps-lg-4 my-2">
                                                        <h6 className="fw-bold">Purple Majesty</h6>
                                                        <p className="fst-italic mb-0">by Soga International</p>
                                                        <div className="wish-ratings text-secondary">
                                                            <span>★</span> <span>★</span> <span>★</span> <span>★</span>
                                                            <span>★</span>
                                                            <p className="text-muted">(No Reviews)</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                            <div className="col-lg-2 col-3  d-flex align-items-center">
                                                <div className="">
                                                    <div className="d-flex align-items-center px-md-3 border-secondary">
                                                        <button
                                                            className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4">-</button>
                                                        <input type="text"
                                                            className="form-control text-center mx-1 quantity-input border-0"
                                                            style={{ width: '40px' }}  value="1"/>
                                                        <button
                                                            className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4">+</button>
                                                    </div>
                                                    <p className="fs-8 mb-0 mt-3">Remove Item <span
                                                            className="remove-wish ms-1 rounded-circle">x</span></p>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center justify-content-end">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <button
                                                className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold">Add
                                                To Cart</button>
                                        </div>
                                    </div>
                                    <div className="wish-content py-3 border-bottom-2">
                                        <div className="row">
                                            <div className="col-lg-6 col-5">
                                                <div className="wish-details row align-items-center my-2">
                                                    <div className="col-12 col-lg-4 my-2">
                                                        <img src="images/products/Purple Majesty.png" className="img-fluid w-100"
                                                            alt="Purple Majesty"/>
                                                    </div>
                                                    <div className="col-12 col-lg-8 ps-lg-4 my-2">
                                                        <h6 className="fw-bold">Purple Majesty</h6>
                                                        <p className="fst-italic mb-0">by Soga International</p>
                                                        <div className="wish-ratings text-secondary">
                                                            <span>★</span> <span>★</span> <span>★</span> <span>★</span>
                                                            <span>★</span>
                                                            <p className="text-muted">(No Reviews)</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                            <div className="col-lg-2 col-3  d-flex align-items-center">
                                                <div className="">
                                                    <div className="d-flex align-items-center px-md-3 border-secondary">
                                                        <button
                                                            className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4">-</button>
                                                        <input type="text"
                                                            className="form-control text-center mx-1 quantity-input border-0"
                                                            style={{ width: '40px' }}  value="1"/>
                                                        <button
                                                            className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4">+</button>
                                                    </div>
                                                    <p className="fs-8 mb-0 mt-3">Remove Item <span
                                                            className="remove-wish ms-1 rounded-circle">x</span></p>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center justify-content-end">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <button
                                                className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold">Add
                                                To Cart</button>
                                        </div>
                                    </div>
                                    <div className="wish-content py-3 border-bottom-2">
                                        <div className="row">
                                            <div className="col-lg-6 col-5">
                                                <div className="wish-details row align-items-center my-2">
                                                    <div className="col-12 col-lg-4 my-2">
                                                        <img src="images/products/Purple Majesty.png" className="img-fluid w-100"
                                                            alt="Purple Majesty"/>
                                                    </div>
                                                    <div className="col-12 col-lg-8 ps-lg-4 my-2">
                                                        <h6 className="fw-bold">Purple Majesty</h6>
                                                        <p className="fst-italic mb-0">by Soga International</p>
                                                        <div className="wish-ratings text-secondary">
                                                            <span>★</span> <span>★</span> <span>★</span> <span>★</span>
                                                            <span>★</span>
                                                            <p className="text-muted">(No Reviews)</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                            <div className="col-lg-2 col-3  d-flex align-items-center">
                                                <div className="">
                                                    <div className="d-flex align-items-center px-md-3 border-secondary">
                                                        <button
                                                            className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4">-</button>
                                                        <input type="text"
                                                            className="form-control text-center mx-1 quantity-input border-0"
                                                            style={{ width: '40px' }}  value="1"/>
                                                        <button
                                                            className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4">+</button>
                                                    </div>
                                                    <p className="fs-8 mb-0 mt-3">Remove Item <span
                                                            className="remove-wish ms-1 rounded-circle">x</span></p>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center justify-content-end">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <button
                                                className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold">Add
                                                To Cart</button>
                                        </div>
                                    </div>
                                    <div className="wish-content py-3 border-bottom-2">
                                        <div className="row">
                                            <div className="col-lg-6 col-5">
                                                <div className="wish-details row align-items-center my-2">
                                                    <div className="col-12 col-lg-4 my-2">
                                                        <img src="images/products/Purple Majesty.png" className="img-fluid w-100"
                                                            alt="Purple Majesty"/>
                                                    </div>
                                                    <div className="col-12 col-lg-8 ps-lg-4 my-2">
                                                        <h6 className="fw-bold">Purple Majesty</h6>
                                                        <p className="fst-italic mb-0">by Soga International</p>
                                                        <div className="wish-ratings text-secondary">
                                                            <span>★</span> <span>★</span> <span>★</span> <span>★</span>
                                                            <span>★</span>
                                                            <p className="text-muted">(No Reviews)</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                            <div className="col-lg-2 col-3  d-flex align-items-center">
                                                <div className="">
                                                    <div className="d-flex align-items-center px-md-3 border-secondary">
                                                        <button
                                                            className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4">-</button>
                                                        <input type="text"
                                                            className="form-control text-center mx-1 quantity-input border-0"
                                                            style={{ width: '40px' }}  value="1"/>
                                                        <button
                                                            className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4">+</button>
                                                    </div>
                                                    <p className="fs-8 mb-0 mt-3">Remove Item <span
                                                            className="remove-wish ms-1 rounded-circle">x</span></p>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center justify-content-end">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <button
                                                className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold">Add
                                                To Cart</button>
                                        </div>
                                    </div>
                                    <div className="wish-last py-3">
                                        <div className="d-flex justify-content-between">
                                            <p className="fs-8 mb-0">Emply Wishlist <span
                                                    className="remove-wish ms-1 rounded-circle">x</span></p>

                                            <p className="mb-0 fw-bold">
                                                Total ₹749
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            }

                            {activeContent === 'myCartContent' && 
                                <div id="myCartContent" className="content-section">
                                    <h2 className="mb-4">MY CART</h2>
                                    <hr/>
                                    <div className="cart-head pb-3 border-bottom-2">
                                        <div className="row">
                                            <div className="col-lg-6 col-5">
                                                <p className="mb-0">Product</p>
                                            </div>
                                            <div className="col-lg-2 col-2">
                                                <p className="mb-0">Price</p>
                                            </div>
                                            <div className="col-lg-2 col-3">
                                                <p className="mb-0">Quantity</p>
                                            </div>
                                            <div className="col-lg-2 col-2 d-flex justify-content-end">
                                                <p className="mb-0">Total</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cart-content py-3 border-bottom-2">
                                        <div className="row">
                                            <div className="col-lg-6 col-5">
                                                <div className="wish-details row align-items-center my-2">
                                                    <div className="col-12 col-lg-4 my-2">
                                                        <img src="images/products/Purple Majesty.png" className="img-fluid w-100"
                                                            alt="Purple Majesty"/>
                                                    </div>
                                                    <div className="col-12 col-lg-8 ps-lg-4 my-2">
                                                        <div className=" mb-3">
                                                            <h6 className="fw-bold">Purple Majesty</h6>
                                                            <p className="fst-italic mb-0">by Soga International</p>
                                                        </div>
                                                        <div className="wish-ratings fst-italic mt-3">
                                                            <p className="mb-0">Standard Delivery - <span
                                                                    className="bg-warning text-white px-1 rounded">₹0</span></p>
                                                            <p className="mb-0">On 03rd Dec 2024 between 7pm - 11pm</p>
                                                            <p className="mb-0">Pincode - 500026</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                            <div className="col-lg-2 col-3  d-flex align-items-center">
                                                <div className="">
                                                    <div className="d-flex align-items-center px-md-3 border-secondary">
                                                        <button
                                                            className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4">-</button>
                                                        <input type="text"
                                                            className="form-control text-center mx-1 quantity-input border-0"
                                                            style={{ width: '40px' }}  value="1"/>
                                                        <button
                                                            className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4">+</button>
                                                    </div>
                                                    <p className="fs-8 mb-0 mt-3">Remove Item <span
                                                            className="remove-wish ms-1 rounded-circle">x</span></p>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center justify-content-end">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <button
                                                className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold">Add
                                                To Cart</button>
                                        </div>
                                    </div>
                                    <div className="cart-content py-3 border-bottom-2">
                                        <div className="row">
                                            <div className="col-lg-6 col-5">
                                                <div className="wish-details row align-items-center my-2">
                                                    <div className="col-12 col-lg-4 my-2">
                                                        <img src="images/products/Purple Majesty.png" className="img-fluid w-100"
                                                            alt="Purple Majesty"/>
                                                    </div>
                                                    <div className="col-12 col-lg-8 ps-lg-4 my-2">
                                                        <div className=" mb-3">
                                                            <h6 className="fw-bold">Purple Majesty</h6>
                                                            <p className="fst-italic mb-0">by Soga International</p>
                                                        </div>
                                                        <div className="wish-ratings fst-italic mt-3">
                                                            <p className="mb-0">Standard Delivery - <span
                                                                    className="bg-warning text-white px-1 rounded">₹0</span></p>
                                                            <p className="mb-0">On 03rd Dec 2024 between 7pm - 11pm</p>
                                                            <p className="mb-0">Pincode - 500026</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                            <div className="col-lg-2 col-3  d-flex align-items-center">
                                                <div className="">
                                                    <div className="d-flex align-items-center px-md-3 border-secondary">
                                                        <button
                                                            className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4">-</button>
                                                        <input type="text"
                                                            className="form-control text-center mx-1 quantity-input border-0"
                                                            style={{ width: '40px' }}  value="1"/>
                                                        <button
                                                            className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4">+</button>
                                                    </div>
                                                    <p className="fs-8 mb-0 mt-3">Remove Item <span
                                                            className="remove-wish ms-1 rounded-circle">x</span></p>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center justify-content-end">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <button
                                                className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold">Add
                                                To Cart</button>
                                        </div>
                                    </div>
                                    <div className="cart-content py-3 border-bottom-2">
                                        <div className="row">
                                            <div className="col-lg-6 col-5">
                                                <div className="wish-details row align-items-center my-2">
                                                    <div className="col-12 col-lg-4 my-2">
                                                        <img src="images/products/Purple Majesty.png" className="img-fluid w-100"
                                                            alt="Purple Majesty"/>
                                                    </div>
                                                    <div className="col-12 col-lg-8 ps-lg-4 my-2">
                                                        <div className=" mb-3">
                                                            <h6 className="fw-bold">Purple Majesty</h6>
                                                            <p className="fst-italic mb-0">by Soga International</p>
                                                        </div>
                                                        <div className="wish-ratings fst-italic mt-3">
                                                            <p className="mb-0">Standard Delivery - <span
                                                                    className="bg-warning text-white px-1 rounded">₹0</span></p>
                                                            <p className="mb-0">On 03rd Dec 2024 between 7pm - 11pm</p>
                                                            <p className="mb-0">Pincode - 500026</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                            <div className="col-lg-2 col-3  d-flex align-items-center">
                                                <div className="">
                                                    <div className="d-flex align-items-center px-md-3 border-secondary">
                                                        <button
                                                            className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4">-</button>
                                                        <input type="text"
                                                            className="form-control text-center mx-1 quantity-input border-0"
                                                            style={{ width: '40px' }}  value="1"/>
                                                        <button
                                                            className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4">+</button>
                                                    </div>
                                                    <p className="fs-8 mb-0 mt-3">Remove Item <span
                                                            className="remove-wish ms-1 rounded-circle">x</span></p>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center justify-content-end">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <button
                                                className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold">Add
                                                To Cart</button>
                                        </div>
                                    </div>
                                    <div className="cart-content py-3 border-bottom-2">
                                        <div className="row">
                                            <div className="col-lg-6 col-5">
                                                <div className="wish-details row align-items-center my-2">
                                                    <div className="col-12 col-lg-4 my-2">
                                                        <img src="images/products/Purple Majesty.png" className="img-fluid w-100"
                                                            alt="Purple Majesty"/>
                                                    </div>
                                                    <div className="col-12 col-lg-8 ps-lg-4 my-2">
                                                        <div className=" mb-3">
                                                            <h6 className="fw-bold">Purple Majesty</h6>
                                                            <p className="fst-italic mb-0">by Soga International</p>
                                                        </div>
                                                        <div className="wish-ratings fst-italic mt-3">
                                                            <p className="mb-0">Standard Delivery - <span
                                                                    className="bg-warning text-white px-1 rounded">₹0</span></p>
                                                            <p className="mb-0">On 03rd Dec 2024 between 7pm - 11pm</p>
                                                            <p className="mb-0">Pincode - 500026</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                            <div className="col-lg-2 col-3  d-flex align-items-center">
                                                <div className="">
                                                    <div className="d-flex align-items-center px-md-3 border-secondary">
                                                        <button
                                                            className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4">-</button>
                                                        <input type="text"
                                                            className="form-control text-center mx-1 quantity-input border-0"
                                                            style={{ width: '40px' }}  value="1"/>
                                                        <button
                                                            className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4">+</button>
                                                    </div>
                                                    <p className="fs-8 mb-0 mt-3">Remove Item <span
                                                            className="remove-wish ms-1 rounded-circle">x</span></p>
                                                </div>

                                            </div>
                                            <div className="col-2 d-flex align-items-center justify-content-end">
                                                <p className="mb-0">₹749</p>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <button
                                                className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold">Add
                                                To Cart</button>
                                        </div>
                                    </div>
                                    <div className="cart-last py-3">
                                        <div className="row">
                                            <div className="col-lg-7 col-4">
                                                <p className="fs-8 mb-0">Emply Cart <span
                                                        className="remove-wish ms-1 rounded-circle">x</span></p>
                                            </div>
                                            <div className="col-lg-3 col-4 ">
                                                <p className="mb-0 fw-bold text-end">
                                                    TOTAL PRODUCTS : 10
                                                </p>
                                            </div>
                                            <div className="col-lg-2 col-4 ">
                                                <p className="mb-0 fw-bold text-end">
                                                    TOTAL : ₹749
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="text-end my-3">
                                        <button className="border-0 bg-secondary text-white px-4 py-2 fw-bold">PROCEED TO
                                            CHECKOUT</button>
                                    </div>
                                </div>
                            }
                            {activeContent === 'myWalletContent' && 
                                <div id="myWalletContent" className="content-section">
                                    <h2 className="mb-4">MY WALLET</h2>
                                    <hr/>
                                    <div className="container my-4">
                                        <ul className="nav nav-tabs" id="transactionTabs" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link wallet-credit active" id="credit-tab"
                                                    data-bs-toggle="tab" data-bs-target="#credit" type="button"
                                                    role="tab">Credit</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link wallet-credit" id="debit-tab" data-bs-toggle="tab"
                                                    data-bs-target="#debit" type="button" role="tab">Debit</button>
                                            </li>
                                        </ul>

                                        <div className="tab-content p-0" id="transactionTabsContent">
                                            <div className="tab-pane fade show active" id="credit" role="tabpanel">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Time</th>
                                                                <th>Description</th>
                                                                <th>Expiry Code</th>
                                                                <th>Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>12/03/2024</td>
                                                                <td>11:30 am</td>
                                                                <td>Description</td>
                                                                <td>12/03/2025</td>
                                                                <td>₹2998</td>
                                                            </tr>
                                                            <tr>
                                                                <td>12/03/2024</td>
                                                                <td>11:30 am</td>
                                                                <td>Description</td>
                                                                <td>12/03/2025</td>
                                                                <td>₹2998</td>
                                                            </tr>
                                                            <tr>
                                                                <td>12/03/2024</td>
                                                                <td>11:30 am</td>
                                                                <td>Description</td>
                                                                <td>12/03/2025</td>
                                                                <td>₹2998</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="debit" role="tabpanel">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Time</th>
                                                                <th>Description</th>
                                                                <th>Expiry Code</th>
                                                                <th>Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>10/02/2024</td>
                                                                <td>9:00 am</td>
                                                                <td>Description</td>
                                                                <td>10/02/2025</td>
                                                                <td>₹1998</td>
                                                            </tr>
                                                            <tr>
                                                                <td>10/02/2024</td>
                                                                <td>9:00 am</td>
                                                                <td>Description</td>
                                                                <td>10/02/2025</td>
                                                                <td>₹1998</td>
                                                            </tr>
                                                            <tr>
                                                                <td>10/02/2024</td>
                                                                <td>9:00 am</td>
                                                                <td>Description</td>
                                                                <td>10/02/2025</td>
                                                                <td>₹1998</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            }                           
                            {activeContent === 'myOrdersContent' && 
                                <div id="myOrdersContent" className="content-section">
                                    <h2 className="mb-4">MY ORDERS</h2>
                                    <hr/>
                                    <p>View your order history and track your current orders.</p>
                                </div>
                            }
                                {activeContent === 'savedCardsContent' && 
                                <div id="savedCardsContent" className="content-section">
                                    <h2 className="mb-4">MY SAVED CARDS</h2>
                                    <hr/>
                                    <p>Manage your saved credit/debit cards for quick payments.</p>
                                </div>
                                }
                            {activeContent === 'signOutContent' && 
                                <div id="signOutContent" className="content-section">
                                    <h2 className="mb-4">SIGN OUT</h2>
                                    <hr/>
                                    <p>You have successfully signed out. Please log in again to access your account.</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Base>
  
    )
}