import React, { useState, useEffect } from 'react';
import Base from './../Config/Base';
// import ProductImg from '../../Assests/Images/Purple Majesty.png';
import axios from "axios";
import { BASE_API_URL, JWT_TOKEN } from '../Config/Config';
import { useNavigate } from 'react-router-dom';
import AddToFavorite from '../Favorites/AddFavorite';
import AddToCart from '../Cart/AddCart';
import { useParams } from 'react-router-dom';


export default function Products () {

    const { CategoryId } = useParams();

    const [userData, setUserData] = useState([]);
    const [products, setProducts] = useState([]);
    const [brands, setBrands] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage, setProductsPerPage] = useState(12);
    const [selectedFavProdId, setSelectedFavProdId] = useState('');
    const [selectedCartProdId, setSelectedCartProdId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [maxPrice, setMaxPrice] = useState(13200); 
    const [filteredProducts, setFilteredProducts] = useState(products);
    const selectedQuantity = 1;
    const navigate = useNavigate();

    useEffect(() => {
        const storedUserData = sessionStorage.getItem('UserData');
        if (storedUserData) {
            try {
                setUserData(JSON.parse(storedUserData));
            } catch (error) {
                console.error("Error parsing storedUserData:", error);
            }
        }
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
            try {

                const formData = new FormData();
                formData.append("id", CategoryId);

                const response = await axios.post(`${BASE_API_URL}get_categories`, {
                    headers: {
                        // "Authorization": `${JWT_TOKEN_POST}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                setSubCategories(response.data.data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchProducts();
    }, [CategoryId]);

    useEffect(() => {
        const fetchBrands = async () => {
            try {

                const response = await axios.post(`${BASE_API_URL}get_brands_data`, {
                });
                setBrands(response.data.data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchBrands();
    }, [CategoryId]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.post(`${BASE_API_URL}get_products`, {
                    headers: {
                        "Authorization": `${JWT_TOKEN}`,
                    },
                });
                setProducts(response.data.data);
                // console.log(response.data.data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchProducts();
    }, [CategoryId]);

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts?.slice(indexOfFirstProduct, indexOfLastProduct);

    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    const changePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const updateItemsPerPage = (event) => {
        setProductsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };
    
    const renderPagination = () => {
        const pages = [];
        const displayRange = 3;
    
        for (let i = 1; i <= totalPages; i++) {
            if (
                i === 1 ||
                i === totalPages ||
                (i >= currentPage - displayRange && i <= currentPage + displayRange)
            ) {
                pages.push(
                <li
                    className={`page-item ${currentPage === i ? "active" : ""}`}
                    key={i}
                >
                    <button className="page-link" onClick={() => changePage(i)}>
                    {i}
                    </button>
                </li>
                );
            } else if (
                pages[pages.length - 1] !== "..."
            ) {
                pages.push(<li key={`dots-${i}`} className="page-item disabled">...</li>);
            }
        }
        return pages;
    };

    const ProductDetailsClick = (productId) => {
        navigate(`/product-details/${productId}`);
    };

    const handleClickFavIcon = (id) => {
        setSelectedFavProdId(id);
    };
    
    const handleClickCartBtn = (id) => {
        setSelectedCartProdId(id);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown1 = () => {
        setIsOpen1(!isOpen1);
    };

    const toggleDropdown2 = () => {
        setIsOpen2(!isOpen2);
    };

    const toggleDropdown3 = () => {
        setIsOpen3(!isOpen3);
    };

    const toggleDropdown4 = () => {
        setIsOpen4(!isOpen4);
    };

    const handleSort = (event) => {
        const { dataset, checked } = event.target;
        setFilters((prev) => ({
            ...prev,
            [dataset.sort]: checked,
        }));
    };

    const [filters, setFilters] = useState({
        topRated: false,
        newest: false,
    });
    
    useEffect(() => {
        let sortedProducts = [...products];
        if (filters.newest) {
            sortedProducts.sort((a, b) => new Date(b.variants[0].date_added) - new Date(a.variants[0].date_added));
        }
        if (filters.topRated) {
            sortedProducts.sort((a, b) => parseFloat(b.rating) - parseFloat(a.rating));
        }
        if (filters.priceHighLow) {
            sortedProducts.sort((a, b) => parseFloat(b.min_max_price.special_price) - parseFloat(a.min_max_price.special_price));
        }
        if (filters.priceLowHigh) {
            sortedProducts.sort((a, b) => parseFloat(a.min_max_price.special_price) - parseFloat(b.min_max_price.special_price));
        }
        setProducts(sortedProducts);
    }, [filters]);

    const handleBrandSelection = (event) => {
        const { checked, dataset } = event.target;
        const brandId = dataset.brandid;
    
        setSelectedBrands((prev) => {
            if (checked) {
                return [...prev, brandId];
            } else {
                return prev.filter((id) => id !== brandId);
            }
        });
    };

    useEffect(() => {
        if (selectedBrands.length > 0) {
            const filteredProducts = products.filter((product) =>
                selectedBrands.includes(product.brand_id)
            );
            setProducts(filteredProducts);
        } else {
            setProducts(products);
        }
    }, [selectedBrands]);

    const handlePriceChange = (event) => {
        setMaxPrice(parseInt(event.target.value, 10));
    };

    useEffect(() => {
        const updatedProducts = products.filter(
            (product) => product.min_max_price.special_price <= maxPrice
        );
        setFilteredProducts(updatedProducts);
    }, [maxPrice, products]);
    
    return (
        <Base>
            <div className="container my-5">
                <div className=" mb-3">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a className="text-decoration-none text-dark" href="#">Jewellery</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{products[0]?.category_name}</li>
                            </ol>
                        </nav>
                    </div>

                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center font-rock">
                            <h1 className="text-uppercase me-3 fs-sm-1">{products[0]?.category_name}</h1>
                            <span className="badge bg-light text-dark fs-6 fw-light" id="total-items">{products?.length} items</span>
                        </div>

                        <div className="d-md-flex d-none align-items-center ">
                            <label for="items-per-page" className="me-2">Show:</label>
                            <select 
                                id="items-per-page" 
                                className="form-select form-select-sm"  
                                onChange={updateItemsPerPage}
                                value={productsPerPage}
                            >
                                <option value="12" selected="">12</option>
                                <option value="24">24</option>
                                <option value="48">48</option>
                                <option value="96">96</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3">
                        <div className="d-lg-none mb-3 text-center py-2 border-block-2">
                            <button id="filter-toggle" className="border-0 bg-white text-uppercase font-rock filter-button">FILTER</button>
                        </div>
                        <div className=" filter-section border-right-5" id="filter-section">
                            <div id="filter-overlay" className="filter-overlay"></div>

                            <div className="filter-header d-lg-none text-end p-3">
                                <i className="fas fa-times close-filter" id="close-filter"></i>
                            </div>

                            <div className="dropdown-container">
                                <h5
                                    className="dropdown-header font-rock fs-6 fw-500 text-dark d-flex justify-content-between"
                                    onClick={toggleDropdown}
                                    style={{ cursor: "pointer" }}
                                >
                                    Sub-category
                                    <span className="dropdown-toggle-icon">
                                        <i className={`fas ${isOpen ? "fa-angle-up" : "fa-angle-down"}`}></i>
                                    </span>
                                </h5>
                                {isOpen && (
                                    <ul className="dropdown-lit font-rock">
                                        <li>Personalised Jewellery (20)</li>
                                    </ul>
                                )}
                            </div>
                            <style>
                                {`
                                    .no-bullets {
                                        list-style: none; /* Removes the bullets */
                                        padding: 0; /* Removes default padding */
                                        margin: 0; /* Removes default margin */
                                    }
                                `}
                            </style>

                            <div className="dropdown-container">
                                <h5 className="dropdown-header font-rock fs-6 fw-500 text-dark d-flex justify-content-between"
                                    onClick={toggleDropdown1}
                                >
                                    Sort By
                                    <span className="dropdown-toggle-icon"><i className="fas fa-angle-down"></i></span>
                                </h5>
                                {isOpen1 && (
                                    <ul className="dropdown-lis font-rock no-bullets">
                                        <li>
                                            <input type="checkbox"         
                                                data-sort="topRated"
                                                checked={filters.topRated}
                                                onChange={handleSort}
                                            /> Top Rated
                                        </li>
                                        <li><input 
                                            type="checkbox" 
                                            data-sort="newest"
                                            checked={filters.newest}
                                            onChange={handleSort}
                                            /> Newest First</li>
                                        <li><input 
                                            type="checkbox" 
                                            data-sort="priceLowHigh"
                                            checked={filters.priceLowHigh}
                                            onChange={handleSort}
                                            /> Price: Low to High</li>
                                        <li><input 
                                            type="checkbox" 
                                            data-sort="priceHighLow"
                                            checked={filters.priceHighLow}
                                            onChange={handleSort}
                                        /> Price: High to Low</li>
                                    </ul>
                                )}
                            </div>

                            <div className="dropdown-container">
                                <h5 className="dropdown-header font-rock fs-6 fw-500 text-dark d-flex justify-content-between"
                                    onClick={toggleDropdown2}
                                >
                                    Brands
                                    <span className="dropdown-toggle-icon"><i className="fas fa-angle-down"></i></span>
                                </h5>
                                {isOpen2 && (
                                    <ul className="dropdown-lis font-rock no-bullets">
                                        {brands && brands?.map((item, index) => (
                                            <li key={index}>
                                                <input 
                                                    type="checkbox"
                                                    data-brandid={item.id}
                                                    onChange={handleBrandSelection}
                                            
                                            /> {item.name}</li>
                                        ))}
                                        
                                    </ul>
                                )}
                            </div>

                            <div className="dropdown-container">
                                <h5 className="dropdown-header font-rock fs-6 fw-500 text-dark d-flex justify-content-between"
                                    onClick={toggleDropdown3}
                                >
                                    Special Features
                                    <span className="dropdown-toggle-icon"><i className="fas fa-angle-down"></i></span>
                                </h5>
                                {isOpen3 && (
                                    <ul className="dropdown-lis font-rock no-bullets">
                                        <li><input type="checkbox" data-feature="on-sale"/> On Sale</li>
                                        <li><input type="checkbox" data-feature="gift-wrap"/> Free Gift Wrap</li>
                                        <li><input type="checkbox" data-feature="personalised"/> Can Be Personalised</li>
                                        <li><input type="checkbox" data-feature="non-personalised"/> Non-Personalised</li>
                                    </ul>
                                )}
                            </div>

                            <div className="dropdown-container">
                                <h5 className="dropdown-header font-rock fs-6 fw-500 text-dark d-flex justify-content-between"
                                    onClick={toggleDropdown4}
                                >
                                    Price
                                    <span className="dropdown-toggle-icon"><i className="fas fa-angle-down"></i></span>
                                </h5>
                                {isOpen4 && (
                                    <div className="dropdown-lis font-rock">
                                        <div className="price-range-container">
                                            <input
                                                type="range"
                                                id="price-range"
                                                min="99"
                                                max="13200"
                                                step="1"
                                                value={maxPrice}
                                                onChange={handlePriceChange}
                                                className="form-range"
                                            />
                                            <div className="price-values">
                                                <span>Price Up To: ₹{maxPrice}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-9 p-3">
                        <div className="row pt-3" id="product-container">
                            {currentProducts && currentProducts?.map((item, index) => (
                                <div className="col-md-6 col-lg-4 mb-4" 
                                    key={index}
                                >
                                    <div className="product-card bg-light position-relative">
                                        <div className="product-fav"><i className="fa-regular fa-heart" onClick={() => handleClickFavIcon(item.id)}></i></div>
                                        <img src={item.image} className="img-fluid rounded-top" alt="Purple Majesty" onClick={() => ProductDetailsClick(item.id)}/>
                                        <div className="product-content p-3">
                                            <h5 className="mt-3 text-center" 
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => ProductDetailsClick(item.id)}>{item.name}</h5>
                                            <p className="text-center">
                                                <span className="product-price fs-5 fw-bold text-dark">₹{item.min_max_price.special_price}</span>
                                                <span className="product-discount text-danger ms-2">₹{item.min_max_price.max_price}</span>
                                            </p>
                                            <div className="text-center"><a href="#"><button className="btn text-white bg-secondary w-80" onClick={() => handleClickCartBtn(item.variants[0].statistics.product_variant_id)}>Add To Cart</button></a></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <nav>
                            <ul className="pagination justify-content-center" id="pagination-container">
                                {renderPagination()}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

            <AddToFavorite productId={selectedFavProdId} />
            <AddToCart productVariantId={selectedCartProdId} qty={selectedQuantity} />
           
        </Base>
    )
}