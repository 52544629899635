import React, { useState, useEffect } from "react";
import Base from "../Config/Base";
import RP4 from '../../Assests/Images/Crimson Anthurium.jpg';
import RP2 from '../../Assests/Images/Purple Majesty.png';
import RP3 from '../../Assests/Images/Gooey Chocolate Cake.png';
import RP1 from '../../Assests/Images/Personalized Anniversary.png';
import { useParams } from 'react-router-dom';
import { BASE_API_URL, JWT_TOKEN } from "../Config/Config";
import axios from "axios";
import AddToCart from "../Cart/AddCart";


export default function ProductDetails () {

    const { ProductId } = useParams();
    const [productDetails, setProductDetails] = useState([]);
    const [productRating, setProductRating] = useState([]);
    const [recomendedProducts, setRecomendedProducts] = useState([]);
    const [productFAQs, setProductFAQs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [mainImage, setMainImage] = useState('');
    const [selectedCartProdId, setSelectedCartProdId] = useState('');
    const [ selectedQuantity, setSelectedQuantity] = useState(1);
    // const [activeFAQ, setActiveFAQ] = useState(null);

    const handleThumbnailClick = (src) => {
        setMainImage(src);
    };

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const formData = new FormData();
                formData.append("id", ProductId);

                const response = await axios.post(`${BASE_API_URL}get_products`, formData, {
                    headers: {
                        Authorization: `${JWT_TOKEN}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                setProductDetails(response.data.data[0]);

                const faqsFormData = new FormData();
                faqsFormData.append("product_id", ProductId);

                const faqResponse = await axios.post(`${BASE_API_URL}get_product_faqs`, faqsFormData, {
                    headers: {
                        Authorization: `${JWT_TOKEN}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                setProductFAQs(faqResponse.data.data);
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [ProductId]);

    useEffect(() => {
        const fetchProductRating = async () => {
            try {
                const formData = new FormData();
                formData.append("product_id", ProductId);

                const response = await axios.post(`${BASE_API_URL}get_product_rating`, formData, {
                    headers: {
                        Authorization: `${JWT_TOKEN}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                setProductRating(response.data);
            } catch (err) {
                // setLoading(false);
                console.log('failed fetching producut rating..!');
            }
        };

        fetchProductRating();
    }, [ProductId]);

    useEffect(() => {
        const fetchRecomendedProduct = async () => {
            try {
                const formData = new FormData();
                formData.append("limit", 1);

                const response = await axios.post(`${BASE_API_URL}get_sections`, formData, {
                    headers: {
                        Authorization: `${JWT_TOKEN}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                setRecomendedProducts(response.data.data[0].product_details);
                console.log(response.data.data[0], 'response.data.data[0]')
            } catch (err) {
                console.log('failed fetching recomended producut..!');
            }
        };

        fetchRecomendedProduct();
    }, []);

    useEffect(() => {
        if (productDetails?.other_images_md?.length > 0) {
            setMainImage(productDetails.other_images_md[0]);
        }
    }, [productDetails]);    

    const [activeTab, setActiveTab] = useState('description');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleIncrement = () => {
        setSelectedQuantity((prev) => prev + 1);
    };
    
    const handleDecrement = () => {
        setSelectedQuantity((prev) => (prev > 1 ? prev - 1 : 1));
    };

    const handleChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 1) {
            setSelectedQuantity(value);
        }
    };

    const handleClickCartBtn = (id) => {
        setSelectedCartProdId(id);
    };

    return (
        <Base>
            <div className="container-fluid my-2">
                <div className="container product-details font-rock">
                    <p className="font-rock">Home & Garder &gt; Living & Homewares</p>
                    <div className="row">
                        <div className="col-lg-6 my-3 d-flex justify-content-center">
                            <div className="image-gallery">
                                <div className="main-image">
                                    <img
                                        id="mainImage"
                                        src={mainImage}
                                        className="img-fluid"
                                        alt="Main Image"
                                    />
                                </div>
                                <div className="thumbnail-images">
                                    {productDetails?.other_images?.map((item, index) => (
                                        <img
                                            className="thumbnail"
                                            key={index}
                                            src={item}
                                            alt={`Thumbnail ${index + 1}`}
                                            onClick={() => handleThumbnailClick(productDetails?.other_images_md[index])}
                                            style={{ cursor: "pointer" }}
                                        />
                                    ))}
                                </div>
                                {/* <div className="main-image">
                                    <img id="mainImage" src={productDetails?.image} className="img-fluid"
                                        alt="Main Image"/>
                                </div>
                                <div className="thumbnail-images">
                                    {productDetails?.other_images?.map((item, index) => (
                                        <img className="thumbnail" key={index} src={item} alt="Thumbnail 1" />
                                    ))}
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 my-3">
                            <div className="">
                                <h1 className="fs-4">{productDetails?.name}</h1>
                                <p className="text-muted">by {productDetails?.seller_name}</p>
                                <p className="text-warning mb-1 text-primary">
                                    {/* <span>★</span> <span>★</span> <span>★</span> <span>★</span>  */}
                                    {productRating.product_rating > 0 
                                        ? [...Array(parseInt(productRating.product_rating))].map((_, index) => (
                                            <span key={index}>★</span>
                                        ))
                                        : ''}
                                    <span className="text-muted">({productRating?.total > 0 ? productRating?.total : 'No Reviews'})</span>
                                </p>
                                <div className="d-flex align-items-center mb-3">
                                    <span className="product-price fs-2">
                                        ₹{productDetails?.min_max_price?.special_price || 0}
                                    </span>
                                    <span className="ms-3 original-price text-decoration-line-through fs-4">
                                        ₹{productDetails?.min_max_price?.max_price || 0}
                                    </span>
                                </div>
                                <p className="text-muted">Tax included. Shipping calculated at checkout.</p>
                                <div className="d-flex align-items-center mb-4">
                                    <button
                                        className="btn btn-outline-secondary decrement-btn"
                                        onClick={handleDecrement}
                                    >
                                        -
                                    </button>
                                    <input
                                        type="text"
                                        className="form-control text-center mx-2 quantity-input"
                                        style={{ width: "50px" }}
                                        value={selectedQuantity}
                                        onChange={handleChange}
                                    />
                                    <button
                                        className="btn btn-outline-secondary increment-btn"
                                        onClick={handleIncrement}
                                    >
                                        +
                                    </button>
                                </div>
                                <p className="fw-bold">Check availability at your pincode</p>
                                <div className="input-group pincode-input mb-4">
                                    <span className="input-group-text bg-primary text-white">
                                        <i className="fas fa-map-marker-alt"></i>
                                    </span>
                                    <input type="text" className="form-control" placeholder="Eg 500082"/>
                                    <button className="btn bg-secondary text-white">Check</button>
                                </div>
                                <div className="mb-4">
                                    <hr/>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button 
                                            className="btn text-white fw-bold w-100 mb-4 fs-5 bg-secondary fs-sm-1"
                                             onClick={() => handleClickCartBtn(productDetails.variants[0].statistics.product_variant_id)}
                                        >
                                            ADD TO CART</button>
                                        <div className="features-icons d-flex justify-content-around">
                                            <div className="text-center">
                                                <i className="fas fa-ban fa text-muted"></i>
                                                <p className="mt-2">No Cancellation</p>
                                            </div>
                                            <div className="text-center">
                                                <i className="fas fa-undo-alt fa text-muted"></i>
                                                <p className="mt-2">No Returnable</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="social-icons mt-4 text-center d-flex justify-content-center">
                                            <a href="#"><i className="fab fa-facebook fs-4 text-secondary mx-2"></i></a>
                                            <a href="#"><i className="fab fa-x-twitter fs-4 text-secondary mx-2"></i></a>
                                            <a href="#"><i className="fab fa-instagram fs-4 text-secondary mx-2"></i></a>
                                            <a href="#"><i className="fab fa-twitter fs-4 text-secondary mx-2"></i></a>
                                            <a href="#"><i className="fas fa-share-alt fs-4 text-secondary mx-2"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container my-5 home-description font-rock">
                <div className="d-flex">
                    <button
                        className={`tab-button ${activeTab === 'description' ? 'active' : ''}`}
                        onClick={() => handleTabClick('description')}
                    >
                        Description
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'delivery' ? 'active' : ''}`}
                        onClick={() => handleTabClick('delivery')}
                    >
                        Delivery
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'reviews' ? 'active' : ''}`}
                        onClick={() => handleTabClick('reviews')}
                    >
                        Reviews
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'faqs' ? 'active' : ''}`}
                        onClick={() => handleTabClick('faqs')}
                    >
                        FAQs
                    </button>
                </div>

                <div id="tab-contents" className="tab-content">
                    <div
                        id="description"
                        className={`tab-pane ${activeTab === 'description' ? 'active' : ''}`}
                    >
                        <div dangerouslySetInnerHTML={{ __html: productDetails?.description }}></div>
                        <div dangerouslySetInnerHTML={{ __html: productDetails?.extra_description }}></div>
                    </div>

                    <div
                        id="delivery"
                        className={`tab-pane ${activeTab === 'delivery' ? 'active' : ''}`}
                    >
                        <p>Delivery information goes here...</p>
                    </div>

                    <div
                        id="reviews"
                        className={`tab-pane ${activeTab === 'reviews' ? 'active' : ''}`}
                    >
                        <p>Customer reviews go here...</p>
                    </div>

                    <div
                        id="faqs"
                        className={`tab-pane ${activeTab === 'faqs' ? 'active' : ''}`}
                    >
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            {productFAQs && productFAQs?.map((item, index) => (
                                <div className="accordion-item" key={index}>
                                    <h2 className="accordion-header" id={`flush-heading-${index}`}>
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#flush-collapse-${index}`}
                                            // aria-expanded={activeFAQ === index ? 'true' : 'false'}
                                            aria-controls={`flush-collapse-${index}`}
                                        >
                                            {item.question}
                                        </button>
                                    </h2>
                                    <div
                                        id={`flush-collapse-${index}`}
                                        className="accordion-collapse collapse"
                                        aria-labelledby={`flush-heading-${index}`}
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div className="accordion-body">
                                            <p>{item.answer}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="recommended-products py-5">
                <h2 className="text-center mb-4 fw-bold text-uppercase text-dark font-rock">
                    Recommended Products
                </h2>
                <div className="row w-100 justify-content-center py-3">
                    <div className="col-10">
                        <div className="owl-carousel product-carousel">
                            {recomendedProducts?.length > 0 ? (
                                recomendedProducts.map((item, index) => (
                                    <div className="product-card" key={index}>
                                        <div>
                                            <div className="product-fav"><i className="fa-regular fa-heart"></i></div>
                                            {item.image ? (
                                                <img src={item.image} alt={item.name || "Product Image"} />
                                            ) : (
                                                <img src="default-image.jpg" alt="Default Product" />
                                            )}
                                            <div className="product-card-content p-md-3 font-rock">
                                                <h5 className="fw-bold fs-5">{item.name || 'Unnamed Product'}</h5>
                                                <p>₹{item.min_max_price?.special_price || 'N/A'}</p>
                                                <button 
                                                    className="add-to-cart" 
                                                    onClick={() => handleClickCartBtn(item.variants[0].statistics.product_variant_id)}
                                                >Add to Cart</button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No recommended products available.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <AddToCart productVariantId={selectedCartProdId} qty={selectedQuantity} />

        </Base>
    )
}