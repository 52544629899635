import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MyAccount from "./Components/MyAccount/MyAccount";
import Home from "./Components/Home/Home";
import AboutUs from "./Components/AboutUs/AboutUs";
import Products from "./Components/Products/Products";
import ProductDetails from "./Components/Products/ProductDetails";


function App() {
    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/my-account" element={<MyAccount />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/products/:CategoryId" element={<Products />} />
                    <Route path="/product-details/:ProductId" element={<ProductDetails />} />
                    <Route path="/" element={<Home />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
