import React, { useState, useEffect } from "react";
import Base from "../Config/Base";
import { BASE_API_URL, JWT_TOKEN } from "../Config/Config";
import axios from "axios";
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


export default function Home () {

    const [sliderImages, setSliederImages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState("");
    const [occasionProducts, setOccasionProducts] = useState([]);
    // const [productTags, setProductTags] = useState([]);
    const [recomendedProducts, setRecomendedProducts] = useState([]);
    // const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchSliderImages = async () => {
            try {
                const response = await axios.post(`${BASE_API_URL}get_slider_images`, {
                    headers: {
                        "Authorization": `${JWT_TOKEN}`,
                    },
                });
                setSliederImages(response.data.data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchSliderImages();
    }, []);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.post(`${BASE_API_URL}get_categories`, {
                    headers: {
                        "Authorization": `${JWT_TOKEN}`,
                    },
                });
                // setCategories(response.data.data);
                setCategories(response.data.data.slice(0, 8));
            } catch (err) {
                setError(err.message);
            }
        };

        fetchCategories();
    }, []);

    // useEffect(() => {
    //     const fetchProducts = async () => {
    //         try {
    //             const formData = new FormData();
    //             formData.append("tags", "HOUSEWARMING, ANNIVERSARY, BABY SHOWER, CONGRATULATIONS, BIRTHDAY, GET WELL SOON, FAREWELL, WEDDINGS");
    //             const response = await axios.post(`${BASE_API_URL}get_products`, formData, {
    //                 headers: {
    //                     "Authorization": `${JWT_TOKEN}`,
    //                     "Content-Type": "multipart/form-data",
    //                 },
    //             });
    
    //             // const products = response.data.data;
    //             // const tags = response.data.tags;
    
    //             // const occasionData = products.filter(product =>
    //             //     tags.some(tag => product.name.toLowerCase() === tag.toLowerCase())
    //             // );                                
                
    //             // console.log(tags, 'tagstags');
    //             // console.log(occasionProducts, 'Matched Products for Occasions');
    
    //             // // setProducts(products);
    //             // setProductTags(tags);
    //             setOccasionProducts(response.data.data);
    //         } catch (err) {
    //             console.log('getting error', err);
    //         }
    //     };
    
    //     fetchProducts();
    // }, []);

    useEffect(() => {
        const fetchRecomendedProduct = async () => {
            try {
                const formData = new FormData();
                formData.append("section_id", 1);

                const response = await axios.post(`${BASE_API_URL}get_sections`, formData, {
                    headers: {
                        Authorization: `${JWT_TOKEN}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                setRecomendedProducts(response.data.data[0].product_details);
                // console.log(response.data.data[0].product_details, 'response.data.data[0]')
            } catch (err) {
                console.log('failed fetching recomended producut..!');
            }
        };

        fetchRecomendedProduct();
    }, []);

    useEffect(() => {
        const fetchRecomendedProduct = async () => {
            try {
                const formData = new FormData();
                formData.append("section_id", 8);

                const response = await axios.post(`${BASE_API_URL}get_sections`, formData, {
                    headers: {
                        Authorization: `${JWT_TOKEN}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                setOccasionProducts(response.data.data[0].product_details);
                console.log(response.data.data[0].product_details, 'data fro occasions')
            } catch (err) {
                console.log('failed fetching occasion producut..!');
            }
        };

        fetchRecomendedProduct();
    }, []);

    // const responsive = {
    //     superLargeDesktop: {
    //       // the naming can be any, depends on you.
    //       breakpoint: { max: 4000, min: 3000 },
    //       items: 5
    //     },
    //     desktop: {
    //       breakpoint: { max: 3000, min: 1024 },
    //       items: 3
    //     },
    //     medium: {
    //       breakpoint: { max: 1024, min: 768 },
    //       items: 4 // Set 4 items for medium devices (between 768px and 1024px)
    //     },
    //     tablet: {
    //       breakpoint: { max: 1024, min: 464 },
    //       items: 2
    //     },
    //     mobile: {
    //       breakpoint: { max: 464, min: 0 },
    //       items: 1
    //     }
    //   };
      
      

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    // };

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1
        }
    };

    const [carousel, setCarousel] = useState(null);

    // const CustomLeftArrow = ({ onClick }) => (
    //     <button onClick={onClick} className="custom-arrow custom-left-arrow">
    //       &lt; {/* You can replace this with any icon or style */}
    //     </button>
    //     // <button onClick={onClick} type="button" className="btn custom-arrow custom-left-arrow" style={{ borderRadius: '50%' }}>
    //     //     <i className="fas fa-chevron-left"></i>
    //     // </button>
    //   );
      
    // const CustomRightArrow = ({ onClick }) => (
    //     <button onClick={onClick} className="custom-arrow custom-right-arrow">
    //         &gt; 
    //     </button>
    // );

    return (
        <Base>
            <style>
                {`
                    .carousel-item {
                        height: 100%;
                    }

                  
                    .carousel-inner img {
                        width: 100%;  /* Ensure the image spans the full width */
                        height: 100%; /* Ensure the image spans the full height */
                        object-fit: cover; /* Prevent image distortion and ensure it covers the space */
                    }

                    @media (max-width: 768px) {
                        .carousel-inner img {
                            height: 20vh;  /* Slightly reduce the height for tablets */
                        }
                    }

                    @media (max-width: 576px) {
                        .carousel-inner img {
                            height: 20vh;  /* Further reduce the height for smaller mobile devices */
                        }
                    }
                    `}
            </style>

            {/* <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {sliderImages &&
                    sliderImages.map((_, index) => (
                        <button
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide-to={index}
                        className={index === 0 ? "active" : ""}
                        aria-label={`Slide ${index + 1}`}
                        key={index}
                        ></button>
                    ))}
                </div>

                <div className="carousel-inner">
                    {sliderImages &&
                        sliderImages.map((item, index) => (
                            <div
                                className={`carousel-item ${index === 0 ? "active" : ""}`}
                                key={index}
                                >
                            <img
                                src={item.image}
                                className="d-block w-100"
                                style={{ objectFit: "cover", height: "100vh" }}
                                alt=""
                            />
                            </div>
                        ))}
                </div>

                <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div> */}

            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {sliderImages &&
                        sliderImages.map((_, index) => (
                            <button
                                type="button"
                                data-bs-target="#carouselExampleControls"
                                data-bs-slide-to={index}
                                className={index === 0 ? "active" : ""}
                                aria-label={`Slide ${index + 1}`}
                                key={index}
                            ></button>
                        ))}
                </div>

                <div className="carousel-inner">
                    {sliderImages &&
                        sliderImages.map((item, index) => (
                            <div
                                className={`carousel-item ${index === 0 ? "active" : ""}`}
                                key={index}
                            >
                                <img
                                    src={item.image}
                                    className="d-block w-100"
                                    alt=""
                                />
                            </div>
                        ))}
                </div>

                <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <div className="container py-5">
                <h2 className="text-center mb-4 fw-bold font-rock">SHOP BY CATEGORY</h2>
                <div className="row justify-content-center py-3">
                    <div className="col-10 m-auto">
                        <div className="row g-4">
                            {categories && categories?.map((item, index) => (
                                <div className="col-lg-3 col-md-4 col-6 my-3" key={index}>
                                    <a href="" className="category-card text-decoration-none text-dark text-center h-100 d-flex flex-column  align-items-center">
                                        <img src={item.image} className="img-fluid mb-3" alt="Christmas Hampers" />
                                        <p className="fw-bold font-rock fs-5">{item.name}</p>
                                    </a>
                                </div>
                            ))}
                        </div>
                        <div className="text-center mt-4">
                            <a href=""><input type="button" value="VIEW ALL CATEGORIES" className="bg-secondary border-0 fs-5 py-2 px-4 text-white fw-bold font-rock"/></a>
                        </div>
                    </div>
                </div>
            </div>


            <div className="recommended-products py-5 bg-primary">
                <h2 className="text-center mb-4 fw-bold text-uppercase text-white font-rock">Recommended Products</h2>
                <div className="row w-100 justify-content-center py-3">
                    <div className="col-10 d-flex">
                    {/* <CustomLeftArrow onClick={() => carousel?.previous()} /> */}

                    <OwlCarousel 
                        className='owl-theme' 
                        loop 
                        margin={10} 
                        nav={false}
                        // items={4}
                        autoplay={true}
                        responsive={{
                            0: {
                                items: 1, // 2 items on mobile (screens with width 0px or more)
                            },
                            600: {
                                items: 3, // 3 items for small screens (e.g., tablets)
                            },
                            1000: {
                                items: 4, // 4 items for larger screens (e.g., desktops)
                            }
                        }}
                    >
                        {recomendedProducts && recomendedProducts?.map((item, index) => (
                            <div 
                                className="item" 
                                style={{ marginRight: '10px', marginLeft: '10px' }} 
                                key={index}
                            >
                                <div className="product-card" style={{ height: '100%' }} >
                                    <div className="">
                                        <div className="product-fav"><i className="fa-regular fa-heart"></i></div>
                                        <img src={item.image} alt="Purple Majesty" style={{ height: '300px', width: '100%' }}/>
                                        <div className="product-card-content p-md-3 font-rock bg-white">
                                            <h5 className="fw-bold fs-6">{item.name}</h5>
                                            <p>₹{item.min_max_price.special_price}</p>
                                            <button className="btn add-to-cart text-white" style={{ backgroundColor: '#9c786c' }}>Add to Cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                        {/* <Carousel 
                            // ref={(node) => setCarousel(node)}
                            responsive={responsive}
                            infinite={true}
                            autoPlaySpeed={1000}
                            swipeable={true}
                            draggable={true}
                            transitionDuration={500}
                            // customLeftArrow={<CustomLeftArrow />}
                            // customRightArrow={<CustomRightArrow />}
                        >
                            {recomendedProducts && recomendedProducts?.map((item, index) => (
                                <div 
                                    className="owl-ite clone" 
                                    style={{ marginRight: '10px', marginLeft: '10px' }} 
                                    key={index}
                                >
                                    <div className="product-card" >
                                        <div className="">
                                            <div className="product-fav"><i className="fa-regular fa-heart"></i></div>
                                            <img src={item.image} alt="Purple Majesty" style={{ height: '300px', width: '100%' }}/>
                                            <div className="product-card-content p-md-3 font-rock bg-white">
                                                <h5 className="fw-bold fs-5">{item.name}</h5>
                                                <p>₹{item.min_max_price.special_price}</p>
                                                <button className="btn btn-primary add-to-cart">Add to Cart</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel> */}

                    {/* <CustomRightArrow onClick={() => carousel?.next()} /> */}

                    {/* <Slider {...settings}>
                        <div className="d-flex">
                        {recomendedProducts && recomendedProducts?.map((item, index) => (
                                        <div className="owl-ite clone" style={{ width: '352.053px', marginRight: '20px' }}>
                                            <div className="product-card" key={index}>
                                                <div className="">
                                                    <div className="product-fav"><i className="fa-regular fa-heart"></i></div>
                                                    <img src={item.image} alt="Purple Majesty"/>
                                                    <div className="product-card-content p-md-3 font-rock">
                                                        <h5 className="fw-bold fs-5">{item.name}</h5>
                                                        <p>₹{item.min_max_price.special_price}</p>
                                                        <button className="add-to-cart">Add to Cart</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                        </div>
                    </Slider> */}

                        {/* <div className="owl-carousel product-carousel owl-loaded owl-drag">
                            <div className="owl-stage-outer">
                                <div className="owl-stage" style={{ transform: 'translate3d(-1860px, 0px, 0px)', transition: '0.8s', width: '4465px'}}>
                                    {recomendedProducts && recomendedProducts?.map((item, index) => (
                                        <div className="owl-item cloned" style={{ width: '352.053px', marginRight: '20px' }}>
                                            <div className="product-card" key={index}>
                                                <div className="">
                                                    <div className="product-fav"><i className="fa-regular fa-heart"></i></div>
                                                    <img src={item.image} alt="Purple Majesty"/>
                                                    <div className="product-card-content p-md-3 font-rock">
                                                        <h5 className="fw-bold fs-5">{item.name}</h5>
                                                        <p>₹{item.min_max_price.special_price}</p>
                                                        <button className="add-to-cart">Add to Cart</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="owl-nav disabled">
                                <button type="button" role="presentation" className="owl-prev">
                                    <span className="custom-nav-arrow custom-nav-prev">
                                        <i className="fas fa-chevron-left"></i>
                                    </span>
                                </button>
                                <button type="button" role="presentation" className="owl-next">
                                    <span className="custom-nav-arrow custom-nav-next"><i className="fas fa-chevron-right"></i></span>
                                </button>
                            </div>
                            <div className="owl-dots disabled">
                                <button role="button" className="owl-dot active">
                                    <span></span>
                                </button>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>

            <div className="container p-5 py-5">
                <h2 className="text-center mb-2 fw-bold text-uppercase font-rock">Elevate the Occasion</h2>
                <h3 className="text-center fw-light mb-4 text-dark h3">Celebrate every moment with curated, thoughtful gifting solutions.</h3>
                <div className="row justify-content-center py-4">
                    {occasionProducts && occasionProducts?.map((product, index) => (
                        product.other_images.map((image, idx) => (
                            <div className="col-lg-3 col-md-4 col-6 my-4" key={index}>
                                <div className="text-center">
                                    <div key={idx}>
                                        <img src={image} className="img-fluid rounded-circle" style={{ width: '90%' }} alt="Occasion Image" />
                                        <h5 className="fw-bold mt-3 font-rock" style={{ fontSize: 'medium' }}>
                                            {product.tags[idx]}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        )) 
                    ))}
                </div>
            </div>


            {/* <div className="container py-5">
                <h2 className="text-center mb-2 fw-bold text-uppercase font-rock">Elevate the Occasion</h2>
                <h3 className="text-center fw-light mb-4 text-dark">Celebrate every moment with curated, thoughtful gifting
                    solutions.
                </h3>
                <div className="row justify-content-center py-4">
                    <div className="col-10">
                        <div className="row g-4">
                            {occasionProducts && occasionProducts?.map((product, index) => (
                                <div className="col-lg-3 col-md-4 col-6 my-4" key={index}>
                                    <div className="text-center">
                                        {product.other_images.map((image, idx) => (
                                            <div key={idx}>
                                                <img src={image} className="img-fluid rounded-circle" alt="Occasion Image" />
                                                <h5 className="fw-bold mt-3 font-rock">
                                                    {product.tags[idx]}
                                                </h5>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div> */}
            
        </Base>
    )
}