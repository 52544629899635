import React, { useState } from 'react';

// import IbizLogo from '../../Assests/Images/41114.png';
// import SideImg from '../../Assests/Images/5.png';
import { useNavigate, Link } from 'react-router-dom';
import { BASE_API_URL } from '../Config/Config';


export default function SignIn() {

    const [loading, setLoading] = useState("");
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('mobile', mobile);
            formData.append('password', password);

            const response = await fetch(`${BASE_API_URL}login`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Login failed');
            }

            const data = await response.json();

            console.log(data.data, 'data data');

            if (data && data.data && data.data.length > 0) {
                sessionStorage.setItem('UserData', data.data[0]);
                const modal = document.getElementById('modal-signin');
                modal.classList.remove('show');
                modal.style.display = 'none';
                document.body.classList.remove('modal-open');
                const backdrop = document.querySelector('.modal-backdrop');
                if (backdrop) backdrop.remove();
            } else {
                console.log('Not found in response');
            }
            
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="modal fade show" id="modal-signin" tabindex="-1" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content text-center">
                    <div className="modal-body">
                        <section id="login_div">
                            <div>
                                <h2 className="mb-3 text-start">Welcome Back</h2>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <p className="lead mb-6 text-start">Fill your email and password to sign in.</p>
                            <form onSubmit={handleLogin}>
                                <div className="text-start mb-2">
                                    <label className='form-label' for="loginEmail">Enter Mobile Number / Email</label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        name="identity"
                                        placeholder="Enter Mobile Number / Email" 
                                        id="loginEmail" 
                                        value={mobile} 
                                        onChange={(e) => setMobile(e.target.value)}
                                        onWheel={(e) => e.target.blur(e)}
                                    />
                                </div>
                                <div className="mb-4 text-start">
                                    <label className='form-label' for="loginPassword">Password</label>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        name="password" 
                                        placeholder="Password"
                                        id="loginPassword" 
                                        value={password} 
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <footer>
                                    <button type="button" data-bs-dismiss="modal" aria-label="Close"
                                        className="btn btn-soft-dark btn-sm rounded-pill">Cancel</button>
                                        <button type="submit" className="submit_btn btn btn-primary btn-sm rounded-pill" disabled={loading}>
                                            {loading ? 'Logging in...' : 'Login'}
                                        </button>
                                </footer>
                                <br/>

                                <p className="mb-1">
                                    <a className="text-decoration-none text-blue fs-15 hover">Forgot Password ?</a>
                                </p>
                                <p className="mb-0">Don't have an account?
                                    <a className="text-decoration-none text-blue fs-15 hover">Sign up Here</a>
                                </p>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );

}