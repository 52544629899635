import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LogoImg from '../../Assests/Images/logo-remove.png';
import FooterPaymentImg from '../../Assests/Images/footer-payments.png';
import PlaneImg from '../../Assests/Images/plane.png';
import AppleImg from '../../Assests/Images/apple.png';
import LockImg from '../../Assests/Images/lock.png';
import AndroidImg from '../../Assests/Images/android.png';
import MessageImg from '../../Assests/Images/message.png';
import { BASE_API_URL, JWT_TOKEN_POST } from "./Config";
import axios from 'axios';
import SignIn from "../Auth/Signin";


const Base = ({ children }) => {

    const [width, setWidth] = useState("50%");
    const [favItems, setFavItems] = useState([]);

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        const storedUserData = sessionStorage.getItem('UserData');
        if (storedUserData) {
            try {
                setUserData(JSON.parse(storedUserData));
            } catch (error) {
                console.error("Error parsing storedUserData:", error);
            }
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.matchMedia("(max-width: 768px)").matches) {
            setWidth("100%");
            } else {
            setWidth("50%");
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const fetchFavItems = async () => {
            try {
                const formData = new FormData();
                formData.append("user_id", 21);

                const response = await axios.post(`${BASE_API_URL}get_favorites`, formData, {
                    headers: {
                        Authorization: `${JWT_TOKEN_POST}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                setFavItems(response.data.data);
                console.log(response.data.data, 'favorites.... in base');
            } catch (err) {
                console.log('failed fetching fav items..!');
            }
        };

        fetchFavItems();
    }, []);

    useEffect(() => {
        if (favItems && favItems.length > 0) {
            setQuantities(favItems.map(() => 1));
        }
    }, [favItems]); 

    const [quantities, setQuantities] = useState([]);

    const handleIncrement = (index) => {
        setQuantities((prev) => {
            const updated = [...prev];
            updated[index] += 1;
            console.log(updated)
            return updated;
        });
    };

    const handleDecrement = (index) => {
        setQuantities((prev) => {
            const updated = [...prev];
            updated[index] = Math.max(1, updated[index] - 1);
            console.log(updated)
            return updated;
        });
    };

    const totalAmount = (favItems || []).reduce((total, item, index) => {
        const price = Number(item.min_max_price?.special_price || 0);
        const quantity = quantities[index] || 1;
        return total + price * quantity;
    }, 0);

    // const ClearAllFavItems = () => {
    //     try {
    //         const formData = new FormData();
    //         formData.append("user_id", userData.id);

    //         const response = await axios.post(`${BASE_API_URL}remove_from_favorites`, formData, {
    //             headers: {
    //                 Authorization: `${JWT_TOKEN_POST}`,
    //                 "Content-Type": "multipart/form-data",
    //             },
    //         });
    //         setFavItems(response.data.data);
    //         console.log(response.data.data, 'favorites.... in base');
    //     } catch (err) {
    //         console.log('failed fetching fav items..!');
    //     }
    // }


    return (
        <div>
            <header className="container-fluid">
                <div className="header-top container py-4 d-lg-block d-none">
                    <div className="row align-items-center">
                        <div className="col-md-2">
                            <Link to="/" className="logo">
                                <img src={LogoImg} className="img-fluid" alt="Logo"/>
                            </Link>
                        </div>
                        <div className="col-md-7 d-none d-lg-block">
                            <div className="row">
                                <div className="col-lg-9 col-md-7">
                                    <div className="input-group">
                                        <input type="text" className="form-control rounded-0" placeholder="Search for gifts" />
                                        <button className="bg-secondary text-white border-0 p-2 px-3">
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-5">
                                    <div className="input-group">
                                        <input type="text" className="form-control rounded-0" placeholder="Hyderabad" />
                                        <button className="bg-secondary text-white border-0 p-2 px-3">
                                            <i className="fa-solid fa-location-dot"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex justify-content-end align-items-center">
                            <div className="d-none d-md-flex gap-4">
                                <a className="text-secondary" style={{ cursor: 'pointer' }}><i className="fa-solid fa-indian-rupee-sign fs-4"></i></a>
                                <a className="text-secondary user-icon" style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#modal-signin"><i className="fa-regular fa-user fs-4"></i></a>
                                <a className="text-secondary" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{ cursor: 'pointer' }}><i className="fa-regular fa-heart fs-4"></i></a>
                                <a className="text-secondary" style={{ cursor: 'pointer' }}><i className="fa-solid fa-cart-shopping fs-4"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <a className="navbar-brand d-lg-none d-block" href="#">
                            <img src={LogoImg} className="img-fluid" alt="Logo"/>
                        </a>
                        <div className="d-lg-none d-block">
                            <a href="#" className="text-secondary mx-2"><i className="fas fa-search fs-4"></i></a>
                            <a href="#" className="text-secondary mx-2 user-icon"><i className="fa-regular fa-user fs-4"></i></a>
                            <a href="#" className="text-secondary mx-2"><i className="fa-solid fa-cart-shopping fs-4"></i></a>
                            <a href="#" className="text-secondary mx-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i className="fa-regular fa-heart fs-4"></i></a>
                        </div>
                        <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse d-lg-block" id="navbarNavDropdown">
                            <ul className="navbar-nav">
                                <li className="nav-item"><Link className="nav-link" to="/">GIFTS</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/products">FOR HER</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/product-details/21">FOR HIM</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/about-us">HOME & GARDEN</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/my-account">PRINTS & ART</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/">FASHION</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/">JEWELLERY</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/">FESTIVE</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/">KIDS</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/">WEDDINGS</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/">CORPORATE</Link></li>
                            </ul>
                        </div>
                    </div>
                </nav>

            </header>

            <div className="hr container border-bottom-2 mb-4"></div>

            <main className=" font-rock">
                {children}
                <div className="home-join py-5 bg-primary">
                    <div className="join-inner container font-rock">
                        <h2 className="text-center text-white fs-1  mb-3">JOIN THE GIIVV CLUB</h2>
                        <h4 className="text-center">Start earning rewards and stay up to date on new arrivals, top-sellers and deals
                            at Giivv!</h4>
                        <div className="row justify-content-center w-100 my-4">
                            <div className="col-lg-4">
                                <div className="join-input position-relative">
                                    <input type="text" placeholder="Email address" className="w-100 border-0 fs-5 p-3 "/>
                                    <div className="join-icon position-absolute">
                                        <i className="fas fa-envelope fs-2 text-secondary"></i>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
                <div className="home-services py-5 bg-light">
                    <div className="container">
                        <div className="home-service-inner row font-rock">
                            <div className="home-service-one col-lg-3 col-md-4 col-6">
                                <div className="text-center"> <img src={PlaneImg} className="w-20 my-4" alt=""/></div>
                                <h5 className="text-center fw-bold">Worldwide Delivery</h5>
                            </div>
                            <div className="home-service-one col-lg-3 col-md-4 col-6">
                                <div className="text-center"> <img src={LockImg} className="w-20 my-3" alt=""/></div>
                                <h5 className="text-center fw-bold">100% Safe & Secure Payments</h5>
                            </div>
                            <div className="home-service-one col-lg-3 col-md-4 col-6">
                                <div className="text-center"> <img src={MessageImg} className="w-20 my-4" alt=""/></div>
                                <h5 className="text-center fw-bold">Dedicated Help Center</h5>
                            </div>
                            <div className="home-service-one col-lg-3 col-md-4 col-6">
                                <div className="text-center">
                                    <img src={AndroidImg} className="w-20 my-4" alt=""/>
                                    <img src={AppleImg} className="w-20 my-4" alt=""/>
                                </div>
                                <h5 className="text-center fw-bold">Shop on the go</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <footer>
                <div className="footer container-fluid py-3">
                    <div className="container footer-inner py-3">
                        <div className="row font-rock">
                            <div
                                className="col-lg-2 col-md-4 d-flex justify-content-md-start text-md-start text-center justify-content-center my-3">
                                <div className="foot-col">
                                    <h5 className="fw-bold mb-3 text-uppercase fs-6">SERVICES</h5>
                                    <ul className="list-unstyled">
                                        <li className="mb-2"><a href="#" className="text-dark text-decoration-none">My Account</a></li>
                                        <li className="mb-2"><a href="#" className="text-dark text-decoration-none">Track Order</a></li>
                                        <li className="mb-2"><a href="#" className="text-dark text-decoration-none">Delivery</a></li>
                                        <li className="mb-2"><a href="#" className="text-dark text-decoration-none">Wishlist</a></li>
                                        <li className="mb-2"><a href="#" className="text-dark text-decoration-none">Gift Wrapping &
                                                Cards</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className="col-lg-2 col-md-4 d-flex justify-content-md-start text-md-start text-center justify-content-center my-3">
                                <div className="foot-col">
                                    <h5 className="fw-bold mb-3 text-uppercase fs-6">About Us</h5>
                                    <ul className="list-unstyled">
                                        <li className="mb-2"><a href="#" className="text-dark text-decoration-none">Meet The Team</a>
                                        </li>
                                        <li className="mb-2"><a href="#" className="text-dark text-decoration-none">Contact Us</a></li>
                                        <li className="mb-2"><a href="#" className="text-dark text-decoration-none">Help Centre</a></li>
                                        <li className="mb-2"><a href="#" className="text-dark text-decoration-none">Blog</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className="col-lg-2 col-md-4 d-flex justify-content-md-start text-md-start text-center justify-content-center my-3">
                                <div className="foot-col">
                                    <h5 className="fw-bold mb-3 text-uppercase fs-6">Information</h5>
                                    <ul className="list-unstyled">
                                        <li className="mb-2"><a href="#" className="text-dark text-decoration-none">Privacy Policy</a>
                                        </li>
                                        <li className="mb-2"><a href="#" className="text-dark text-decoration-none">Returns Policy</a>
                                        </li>
                                        <li className="mb-2"><a href="#" className="text-dark text-decoration-none">Terms &
                                                Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="foot-col">
                                    <div className="row">
                                        <div className="col-md-8 d-flex justify-content-md-start justify-content-center  my-3">
                                            <div className="text-md-start text-center">
                                                <h5 className="fw-bold mb-3 text-uppercase fs-6">Secure Payments</h5>
                                                <img src={FooterPaymentImg} className="img-fluid" alt=""/>
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-4 d-flex justify-content-md-start text-md-start text-center justify-content-center my-3">
                                            <div className="foot-social">
                                                <h5 className="fw-bold mb-3 text-uppercase fs-6">Social Media</h5>
                                                <div className="foot-icons d-flex justify-content-between">
                                                    <a href="#" className="text-secondary fs-4 mx-2"><i
                                                            className="fa-brands fa-facebook-f"></i></a>
                                                    <a href="#" className="text-secondary fs-4 mx-2"><i
                                                            className="fa-brands fa-x-twitter"></i></a>
                                                    <a href="#" className="text-secondary fs-4 mx-2"><i
                                                            className="fa-brands fa-instagram"></i></a>
                                                    <a href="#" className="text-secondary fs-4 mx-2"><i
                                                            className="fa-brands fa-pinterest"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom bg-primary py-2 font-rock">
                    <p className="text-white text-center mb-0 fs-5">Copyright 2024 giivv | All rights reserved |</p>
                </div>
            </footer>

            <div 
                className="offcanvas offcanvas-end" 
                tabIndex="-1" 
                id="offcanvasRight" 
                aria-labelledby="offcanvasRightLabel"
                style={{ width }}
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">MY WISHLIST</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                
                <div className="offcanvas-body">
                    <div id="myWishlistContent" className="content-section">
                        <div className="wish-head pb-3 border-bottom-2">
                            <div className="row">
                                <div className="col-lg-6 col-5">
                                    <p className="mb-0">Product</p>
                                </div>
                                <div className="col-lg-2 col-2">
                                    <p className="mb-0">Price</p>
                                </div>
                                <div className="col-lg-2 col-3">
                                    <p className="mb-0">Quantity</p>
                                </div>
                                <div className="col-lg-2 col-2 d-flex justify-content-end">
                                    <p className="mb-0">Total</p>
                                </div>
                            </div>
                        </div>
                        {favItems &&
                            favItems.map((item, index) => {
                                const price = Number(item.min_max_price?.special_price || 0);
                                const quantity = quantities[index] || 1;

                                return (
                                    <div className="wish-content py-3 border-bottom-2" key={index}>
                                        <div className="row">
                                            <div className="col-lg-6 col-5">
                                                <div className="wish-details row align-items-center my-2">
                                                    <div className="col-12 col-lg-4 my-2">
                                                        <img
                                                            src={item.image}
                                                            className="img-fluid w-100"
                                                            alt="Purple Majesty"
                                                        />
                                                    </div>
                                                    <div className="col-12 col-lg-8 ps-lg-4 my-2">
                                                        <h6 className="fw-bold">{item.name}</h6>
                                                        <p className="fst-italic mb-0">by {item.seller_name}</p>
                                                        <div className="wish-ratings text-secondary">
                                                            <span>
                                                                {[...Array(5)].map((_, idx) => (
                                                                    <span
                                                                        key={idx}
                                                                        style={{
                                                                            color:
                                                                                idx < Math.round(Number(item.rating))
                                                                                    ? "gold"
                                                                                    : "lightgray",
                                                                        }}
                                                                    >
                                                                        ★
                                                                    </span>
                                                                ))}
                                                            </span>
                                                            <span className="text-muted">
                                                                ({item.no_of_ratings})
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2 d-flex align-items-center">
                                                <p className="mb-0">₹{price}</p>
                                            </div>
                                            <div className="col-lg-2 col-3 d-flex align-items-center">
                                                <div>
                                                    <div className="d-flex align-items-center px-md-3 border-secondary">
                                                        <button
                                                            className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4"
                                                            onClick={() => handleDecrement(index)}
                                                        >
                                                            -
                                                        </button>
                                                        <input
                                                            type="text"
                                                            className="form-control text-center mx-1 quantity-input border-0"
                                                            style={{ width: "40px" }}
                                                            value={quantity}
                                                            readOnly
                                                        />
                                                        <button
                                                            className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4"
                                                            onClick={() => handleIncrement(index)}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                    <p className="fs-8 mb-0 mt-3">
                                                        Remove Item{" "}
                                                        <span className="remove-wish ms-1 rounded-circle">x</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-2 d-flex align-items-center justify-content-end">
                                                <p className="mb-0">₹{price * quantity}</p>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <button className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold">
                                                Add To Cart
                                            </button>
                                        </div>
                                    </div>
                                );
                        })}
                        <div className="wish-last py-3">
                            <div className="d-flex justify-content-between">
                                <p className="fs-8 mb-0">Empty Wishlist <span
                                        className="remove-wish ms-1 rounded-circle" >x</span></p>
                                <p className="mb-0 fw-bold">
                                    Total  ₹{totalAmount.toFixed(2)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SignIn />
        </div>

    );
};

export default Base;
