import React from "react";
import Base from "../Config/Base";
import AboutImg from '../../Assests/Images/about-img.jpg';
import Cat1 from '../../Assests/Images/christmas-hamper.jpg';
import Cat2 from '../../Assests/Images/fasion.jpg';
import Cat3 from '../../Assests/Images/gifts-for-him.jpg';
import Cat4 from '../../Assests/Images/gifts-for-her.jpg';
import Cat5 from '../../Assests/Images/home-garden.jpg';
import Cat6 from '../../Assests/Images/wedding.jpg';
import Cat7 from '../../Assests/Images/kids-baby.jpg';
import Cat8 from '../../Assests/Images/occasion.jpg';
import PlaneImg from '../../Assests/Images/plane.png';
import AppleImg from '../../Assests/Images/apple.png';
import LockImg from '../../Assests/Images/lock.png';
import AndroidImg from '../../Assests/Images/android.png';
import MessageImg from '../../Assests/Images/message.png';


export default function AboutUs () {
    return (
        <Base>
            <div className="bread container my-4">
                <p className="font-rock">Home &gt; About Us</p>
            </div>
            <div className="container-fluid my-2">
                <div className="container font-rock">
                    <h1 className="text-uppercase">About Us</h1>
                    <div className="row">
                        <div className="col-lg-6 my-3 d-flex justify-content-center">
                            <img src={AboutImg} className="img-fluid" alt="about-img"/>
                        </div>
                        <div className="col-lg-6 my-3 d-flex justify-content-center align-items-center">
                            <div className="about-content fs-7">
                                <p>GIIVV.com aims to be India’s leading destination for all Gifting Needs &amp; Home Decorative
                                    Services, offering a wide range of thoughtfully curated gift items for every occasion.
                                </p>
                                <p>In India, Countless Artisans, Craftspeople and many talented individual skills remain
                                    unknown, their creations unappreciated, and their livelihoods are unsecured.
                                </p>
                                <p>"Giivv.com is act as a gateway to India's vibrant cultural heritage, where tradition
                                    meets innovation. Our platform connects you with artisans and makers from across India,
                                    showcasing an exquisite array of handicrafts, Decoratives, and vintage products."
                                </p>
                                <div>
                                    <p>By leveraging the power of the internet, we connect:</p>
                                    <ul>
                                        <li>Artisans to global customers</li>
                                        <li>Local communities to international markets</li>
                                    </ul>
                                    <p>Tradition to modernity</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-6 col-md-12 mb-4">
                        <h2>OUR PURPOSE</h2>
                        <p>
                            At GiiVv.com, we aim to:
                        </p>
                        <ul>
                            <li>Preserve India's rich cultural heritage</li>
                            <li>Empower local artisans and communities</li>
                            <li>Promote sustainable and fair trade practices</li>
                            <li>Provide unique, authentic Indian products to global customers</li>
                        </ul>
                        <p>
                            Giivv.com is dedicated to discovering, promoting, and preserving India's rich cultural heritage
                            by providing a global platform for unknown artisans, unsung heroes, and unrecognized products,
                            thereby empowering their livelihoods and bringing value to their creations. And also to make
                            gift-giving a delightful and seamless experience to your loved ones by providing a vast
                            selection of curated products
                        </p>
                        <p><strong>Explore Our Collections:</strong></p>
                        <ul>
                            <li>Handicrafts</li>
                            <li>Decoratives</li>
                            <li>Spiritual &amp; Ritual products</li>
                            <li>Artisanal crafts</li>
                            <li>Customized/Personalized Gifts</li>
                            <li>Luxury Gifts</li>
                            <li>Wedding Gifts &amp; Dresses</li>
                            <li>Wellness &amp; Healthy Delights</li>
                            <li>Fashion &amp; Toys</li>
                            <li>Festive &amp; Seasonal</li>
                        </ul>
                        <div className="mb-4">
                            <h2>OUR MISSION</h2>
                            <p>
                                Empowering Indian artisans to thrive in a global marketplace, preserving India's rich
                                cultural
                                heritage, and delighting customers with authentic, high-quality handicrafts, while fostering
                                a
                                sustainable and equitable ecosystem.“
                            </p>
                        </div>
                        <div className="mb-4">
                            <h2>OUR VISION</h2>
                            <p>
                                "To provide a platform for Indian artisans to showcase their craftsmanship, promote cultural
                                heritage, and deliver exceptional customer experiences, while driving sustainable growth and
                                social impact."
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 mb-4">
                        <h2>CORE VALUES</h2>
                        <ul>
                            <li><strong>Empowerment:</strong> Enhance livelihoods and financial stability for Indian
                                artisans &amp; Craftsman.</li>
                            <li><strong>Cultural Preservation:</strong> Promote and Safeguarding India's cultural heritage
                                for future generations.
                            </li>
                            <li><strong>Diversity:</strong> Celebrating the richness of Indian crafts and traditions.</li>
                            <li><strong>Customer Delight:</strong> Deliver exceptional customer experiences through quality
                                products and service.</li>
                            <li><strong>Sustainability:</strong> Foster environmentally and socially responsible
                                practices.</li>
                            <li><strong>Innovation:</strong> Continuously innovate and improve products, processes, and
                                technology.</li>
                        </ul>
                        <p><strong>Unique Selling Proposition (USP):</strong></p>
                        <ul>
                            <li>Exclusive, curated collection of Indian handicrafts and cultural products.</li>
                            <li>Direct-from-artisans, ensuring authenticity and fair pricing.</li>
                            <li>Sharing artisans' journeys, traditions, and craftsmanship.</li>
                            <li>Global shipping, easy ordering.</li>
                        </ul>
                        <div className="">
                            <h2>BRIEF ABOUT OUR BRAND &amp; PRODUCTS</h2>
                            <p>
                                GIIVV.com is India’s leading Online Gifting and Decoratives store, offering a vast selection
                                of
                                gift categories designed to suit every occasion and relationship. From Rakhi gifts and
                                Diwali
                                collections to Personalized Gifts, Fresh Flowers, Plants, Gourmet Hampers, Home and Living
                                Items, Jewellery, Fashion &amp; Lifestyle Products, Toys, Handicrafts &amp; Home-made Cakes,
                                Chocolates,
                                Cookies etc.
                            </p>
                            <p>
                                WWe take pride in delivering joy to our customers by curating products that are both unique
                                and
                                memorable.
                            </p>
                            <p>
                                We have a gift for every Occasion &amp; Special moments! With Same Day Delivery.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid pb-5">
                <div className="container d-flex flex-wrap justify-content-between">
                    <div className="category-card  flex-fill text-center rounded-0">
                        <a href="products.html" className="category-card text-decoration-none text-dark h-100">
                            <img src={Cat1} className="img-fluid" alt="Christmas Hampers"/>
                            <p className="fw-bold mt-3 font-rock fs-8">CHRISTMAS HAMPERS</p>
                        </a>
                    </div>
                    <div className="category-card  flex-fill text-center rounded-0">
                        <a href="products.html" className="category-card text-decoration-none text-dark h-100">
                            <img src={Cat4} className="img-fluid" alt="Gifts for Her"/>
                            <p className="fw-bold mt-3 font-rock fs-8">GIFTS FOR HER</p>
                        </a>
                    </div>
                    <div className="category-card  flex-fill text-center rounded-0">
                        <a href="products.html" className="category-card text-decoration-none text-dark h-100">
                            <img src={Cat3} className="img-fluid" alt="Gifts for Him"/>
                            <p className="fw-bold mt-3 font-rock fs-8">GIFTS FOR HIM</p>
                        </a>
                    </div>
                    <div className="category-card  flex-fill text-center rounded-0">
                        <a href="products.html" className="category-card text-decoration-none text-dark h-100">
                            <img src={Cat6} className="img-fluid" alt="Wedding"/>
                            <p className="fw-bold mt-3 font-rock fs-8">WEDDING</p>
                        </a>
                    </div>
                    <div className="category-card  flex-fill text-center rounded-0">
                        <a href="products.html" className="category-card text-decoration-none text-dark h-100">
                            <img src={Cat7} className="img-fluid" alt="Kids &amp; Baby"/>
                            <p className="fw-bold mt-3 font-rock fs-8">KIDS &amp; BABY</p>
                        </a>
                    </div>
                    <div className="category-card  flex-fill text-center rounded-0">
                        <a href="products.html" className="category-card text-decoration-none text-dark h-100">
                            <img src={Cat8} className="img-fluid" alt="Occasions"/>
                            <p className="fw-bold mt-3 font-rock fs-8">OCCASIONS</p>
                        </a>
                    </div>
                    <div className="category-card  flex-fill text-center rounded-0">
                        <a href="products.html" className="category-card text-decoration-none text-dark h-100">
                            <img src={Cat5} className="img-fluid" alt="Home &amp; Garden"/>
                            <p className="fw-bold mt-3 font-rock fs-8">HOME &amp; GARDEN</p>
                        </a>
                    </div>
                    <div className="category-card  flex-fill text-center rounded-0">
                        <a href="products.html" className="category-card text-decoration-none text-dark h-100">
                            <img src={Cat2} className="img-fluid" alt="Fashion"/>
                            <p className="fw-bold mt-3 font-rock fs-8">FASHION</p>
                        </a>
                    </div>
                </div>
                <div className="text-center mt-4">
                    <a href="products.html"><input type="button" value="VIEW ALL CATEGORIES" className="bg-secondary border-0 fs-5 py-2 px-4 text-white fw-bold font-rock"/></a>
                </div>
            </div>
        </Base>
    )
}